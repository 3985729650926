<template>
  <div v-if="Object.keys(view_user).length !== 0" >
    <div class="mb-1">
      <b-modal id="view-user-modal" title="View User Details" size="lg" hide-footer>
        <div class="modal-content bg-white rounded">
          <table class="table table-sm">
            <thead>
              <tr>
                <th>Name</th>
                <td>{{ view_user.name }}</td>
              </tr>
              <tr>
                <th>Email Address</th>
                <td>{{ view_user.email }}</td>
              </tr>
              <tr>
                <th>Phone No.</th>
                <td>{{ view_user.phone_no }}</td>
              </tr>
              <tr>
                <th>Office Id</th>
                <td>{{ view_user.office_id }}</td>
              </tr>
              <tr>
                <th>Warehouse</th>
                <td>{{ view_user.warehouse ? view_user.warehouse.name : 'N/A' }}</td>
              </tr>
              <tr>
                <th>Hub</th>
                <td>{{ view_user.hub ? view_user.hub.name : 'N/A' }}</td>
              </tr>
              <tr>
                <th>Status</th>
                <td>{{ view_user.is_active == 1 ? 'Active' : 'Inactive' }}</td>
              </tr>
              <tr>
                <th>Roles</th>
                <td>
                  <span v-for="(role, index) in  view_user.roles" :key="index">
                    {{ role.name}} <br/>
                  </span>
                </td>
              </tr>
            </thead>
          </table>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
export default {
  name: 'viewUser',
  props: ['view_user'],
  data() {
    return {}
  },
}
</script>
