<template>
  <div class="card">
    <div class="card-body">
      <div class="table-responsive">
        <a-tooltip placement="top">
          <template slot="title"><span>Add User</span></template>
          <a href="javascript: void(0);" class="btn btn-sm btn-success float-right mb-1" v-b-modal.add-user-modal @click="$bvModal.show('add-user-modal'), resetForm()">
            <i class="fa fa-plus"/> Add User
          </a>
        </a-tooltip>
        <table class="table table-sm table-bordered table-striped" id="user_list">
          <thead>
          <tr>
            <th>Name</th>
            <th style="width: 25%">Login Name</th>
            <th style="width: 10%">Picture</th>
            <th>Hub</th>
            <th style="width: 25%">Action</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(v_user, index) in users" :key="index">
            <td :style="v_user.is_active == 0 ? 'color:red' : ''">{{ v_user.name }}</td>
            <td :style="v_user.is_active == 0 ? 'color:red' : ''">{{ v_user.user_name }}</td>
            <td>
                <span class="ant-badge">
                <a :href="v_user.profile_picture" target="_blank" download>
                   <span class="ant-avatar ant-avatar-lg ant-avatar-square ant-avatar-icon index_avatar_1A3j0">
                        <img :src="v_user.profile_picture_url"  width="1em" height="1em">
                   </span>
                </a>
              </span>
            </td>
            <td :style="v_user.is_active == 0 ? 'color:red' : ''">{{ v_user.hub ? v_user.hub.name : 'N/A' }}</td>
            <td>
              <a class="btn btn-sm btn-primary mr-2" href="javascript: void(0);" @click.prevent="edit(v_user.id)"><i class="fe fe-edit mr-2"></i>Update</a>
              <a class="btn btn-sm btn-info" href="javascript: void(0);" @click.prevent="view(v_user), $bvModal.show('view-user-modal')"><i class="fe fe-info mr-2"></i>View</a>
<!--              <span v-if="user.email !== v_user.email">-->
<!--                <a v-if="user.role.includes('head_of_admin')" class="btn btn-sm btn-danger mr-2" href="javascript: void(0);" @click.prevent="onDelete(v_user.id)"><i class="fe fe-trash mr-2"></i>Delete</a>-->
<!--              </span>-->
            </td>
          </tr>
          </tbody>
        </table>
        <a-skeleton active :loading="loading"></a-skeleton>
        <h5 class="text-center text-secondary" v-if="users.length === 0 && !flag">
          <Empty :image="simpleImage"></Empty>
        </h5>
      </div>
    </div>
    <add-user
      @getUsers="getUsers"
      @resetForm="resetForm"
      ref="addUser">
    </add-user>
    <update-user
      @getUsers="getUsers"
      ref="updateUser">
    </update-user>
    <view-user
      :view_user="view_user"
      ref="viewUser">
    </view-user>
  </div>
</template>

<script>
import { Empty } from 'ant-design-vue'
import apiClient from '@/services/axios'
import addUser from '@/views/userManagement/user/modal/addUser'
import updateUser from '@/views/userManagement/user/modal/updateUser'
import viewUser from '@/views/userManagement/user/modal/viewUser'
import $ from 'jquery'
import { mapGetters } from 'vuex'

export default {
  name: 'List',
  components: { Empty, addUser, updateUser, viewUser },
  data() {
    return {
      view_user: {},
      users: [],
      update_user: {},
      loading: false,
      loader: false,
      flag: false,
      btnEdit: false,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
    }
  },
  computed: {
    ...mapGetters('user', ['user']),
  },
  mounted() {
    this.getUsers()
  },
  methods: {
    getUsers() {
      this.flag = true
      this.loading = true
      this.loader = true
      apiClient.get('api/users')
        .then(response => {
          this.loading = false
          this.flag = true
          this.loader = false
          this.users = response.data.users
          setTimeout(function() {
            $('#user_list').DataTable(
              {
                bRetrieve: true,
                pagingType: 'full_numbers',
                pageLength: 10,
                processing: true,
                dom: 'Blfrtip',
                buttons: ['copy', 'csv', 'print'],
              },
            )
          }, 3000,
          )
        })
        .catch(error => {
          console.log(error)
        })
    },
    edit(userId) {
      this.$refs.updateUser.onUpdate(userId)
      this.$bvModal.show('update-user-modal')
    },
    onDelete(userId) {
      if (confirm('Do you really want to delete?')) {
        apiClient.delete('api/users/' + userId).then(response => {
          if (!response.error) {
            if (response.data.error === true) {
              this.$notification.error({
                message: response.data.message,
              })
            } else {
              this.$notification.success({
                message: response.data.message,
              })
              $('#user_list').DataTable().destroy()
              this.getUsers()
            }
          } else {
            this.$notification.error({
              message: 'Deleted Failed',
            })
          }
        }).catch(error => {
          console.log(error)
        })
      }
    },
    resetForm() {
      this.show = false
      this.btnEdit = false
      this.update_user = {}
    },
    view(user) {
      this.view_user = {}
      this.view_user = user
    },
  },
}
</script>
<style scoped>

</style>
